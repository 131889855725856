



















































































import Vue from "vue";
import { Moment } from "moment";
import {
  DATE_FORMAT_REPORT,
  DEFAULT_DATE_FORMAT,
} from "@/models/constant/date.constant";
import { FormModel } from "ant-design-vue";
import { reportService } from "@service/report.service";
import MNotificationVue from "@/mixins/MNotification.vue";
import { downloadFile } from "@/helpers/file-reader";
import { ReportSalesValuationDetail } from "@/models/interface-v2/report.interface";

export default Vue.extend({
  name: "ReportValuationDetail",
  components: {
    CSelectMasterProduct: () => import("@/components/shared/select-master-product/CSelectMasterProduct.vue"),
    CSelectProductCategory: () => import("@/components/shared/select-master-product-category/CSelectMasterProductCategory.vue"),
    CSelectWarehouse: () => import("@/components/shared/select-warehouse/CSelectWarehouse.vue"),
  },
  mixins: [MNotificationVue],
  data() {
    return {
      DEFAULT_DATE_FORMAT,
      form: {
        branchId: undefined as string | undefined,
        warehouseId: undefined as string | undefined,
        locationId: undefined as string | undefined,
        productCategoryId: undefined as string | undefined,
        productId: undefined as string | undefined,
        date: [] as Moment[],
      },
      rules: {
        branchId: [
          {
            required: false,
            message: () => this.$t("lbl_validation_required_error"),
          },
        ],
        warehouseId: [
          {
            required: false,
            message: () => this.$t("lbl_validation_required_error"),
          },
        ],
        locationId: [
          {
            required: false,
            message: () => this.$t("lbl_validation_required_error"),
          },
        ],
        productId: [
          {
            required: false,
            message: () => this.$t("lbl_validation_required_error"),
          },
        ],
        productCategoryId: [
          {
            required: false,
            message: () => this.$t("lbl_validation_required_error"),
          },
        ],
        date: [
          {
            required: true,
            message: () => this.$t("lbl_validation_required_error"),
          },
        ],
      },
      loading: false,
      searchByProductCategory: "",
    };
  },
  methods: {
    validateForm(): void {
      const form = this.$refs.form as FormModel;
      form.validate((valid: boolean) => {
        if (valid) {
          this.handleDownload();
        } else {
          this.showNotifValidationError();
        }
      });
    },
    async handleDownload(): Promise<void> {
      try {
        const [ startDate, endDate ] = this.form.date;
        this.loading = true;

        const searchBy: string[] = [];
        if (this.form.productCategoryId) {
          searchBy.push(`categoryId~${this.form.productCategoryId}`);
        }
        if (this.form.branchId) {
          searchBy.push(`branchId~${this.form.branchId}`);
        }
        if (this.form.warehouseId) {
          searchBy.push(`warehouseId~${this.form.warehouseId}`);
        }
        if (this.form.productId) {
          searchBy.push(`productId~${this.form.productId}`);
        }

        const params: ReportSalesValuationDetail = {
          end: endDate.format(DATE_FORMAT_REPORT),
          locationId: this.form.locationId || null,
          params: `PT. GLOBAL PRATAMA WIJAYA,${startDate.format(DATE_FORMAT_REPORT)},${endDate.format(DATE_FORMAT_REPORT)}`,
          search: searchBy.join("_AND_"),
          start: startDate.format(DATE_FORMAT_REPORT),
        };
        const file = await reportService.valuationDetail(params);
        downloadFile(
          new Blob([file]),
          `report_valuation_detail_${startDate.format(DATE_FORMAT_REPORT)}_to_${endDate.format(DATE_FORMAT_REPORT)}.xlsx`
        );
      } catch (error) {
        this.showErrorMessage("notif_download_error");
      } finally {
        this.loading = false;
      }
    },
    handleClear(): void {
      this.form = {
        warehouseId: undefined,
        locationId: undefined,
        productCategoryId: undefined,
        branchId: undefined,
        productId: undefined,
        date: [],
      };
    },
    handleBack(): void {
      this.$router.back();
    },
    onChangeProductCategory(e: string): void {
      this.form.productId = undefined;
      if (!e) return;
      this.searchByProductCategory = `category.secureId~${e}`;
    },
    onChangeBranch(): void {
      this.form.warehouseId = undefined;
      this.form.locationId = undefined;
    },
    onChangeWarehouse(): void {
      this.form.locationId = undefined;
    },
  },
});
