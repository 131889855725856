var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_valuation_report_detail") } },
    [
      _c(
        "a-form-model",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-col": { span: 4 },
            "wrapper-col": { span: 14 }
          }
        },
        [
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("lbl_product_category"),
                prop: "productCategoryId"
              }
            },
            [
              _c("CSelectProductCategory", {
                on: { change: _vm.onChangeProductCategory },
                model: {
                  value: _vm.form.productCategoryId,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "productCategoryId", $$v)
                  },
                  expression: "form.productCategoryId"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_product_code"), prop: "productId" } },
            [
              _c("CSelectMasterProduct", {
                attrs: { "prop-search-by": _vm.searchByProductCategory },
                model: {
                  value: _vm.form.productId,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "productId", $$v)
                  },
                  expression: "form.productId"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { prop: "branchId", label: _vm.$t("lbl_branch") } },
            [
              _c("CSelectBranch", {
                on: { change: _vm.onChangeBranch },
                model: {
                  value: _vm.form.branchId,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "branchId", $$v)
                  },
                  expression: "form.branchId"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { prop: "warehouseId", label: _vm.$t("lbl_warehouse") } },
            [
              _c("CSelectWarehouse", {
                attrs: { "by-branch": _vm.form.branchId },
                on: { change: _vm.onChangeWarehouse },
                model: {
                  value: _vm.form.warehouseId,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "warehouseId", $$v)
                  },
                  expression: "form.warehouseId"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { prop: "locationId", label: _vm.$t("lbl_rack") } },
            [
              _c("CSelectRack", {
                attrs: { "by-warehouse": _vm.form.warehouseId },
                model: {
                  value: _vm.form.locationId,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "locationId", $$v)
                  },
                  expression: "form.locationId"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_date"), prop: "date" } },
            [
              _c("a-range-picker", {
                attrs: {
                  placeholder: [
                    _vm.$t("lbl_start_date"),
                    _vm.$t("lbl_end_date")
                  ],
                  format: _vm.DEFAULT_DATE_FORMAT
                },
                model: {
                  value: _vm.form.date,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "date", $$v)
                  },
                  expression: "form.date"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { "wrapper-col": { span: 14, offset: 4 } } },
            [
              _c(
                "a-space",
                [
                  _c("a-button", { on: { click: _vm.handleClear } }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_clear")) + " ")
                  ]),
                  _c(
                    "a-button",
                    {
                      attrs: {
                        icon: "download",
                        type: "primary",
                        loading: _vm.loading
                      },
                      on: { click: _vm.validateForm }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }